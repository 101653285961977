<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDAC53"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Naturalists</span>
          <v-spacer></v-spacer>
          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-5>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="70%"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="font-family: poppinsmedium"
                  outlined
                  block
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Naturalists
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-card-title>
                    <span class="headline">Add Naturalists</span>
                  </v-card-title>
                  <v-card-text>
                    <!-- <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          
                        </v-col>
                      </v-row>
                    </v-container> -->
                    <v-layout wrap>
                      <v-flex xs12 align-self-center text-left pt-5>
                        <v-text-field
                          v-model="name"
                          label="Naturalist Name"
                          :rules="[rules.required]"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 pt-5 v-if="flagg">
                        <ImageComp
                          @stepper="winStepper"
                          :height="'2118'"
                          :width="'8001'"
                          :heading="'Upload Image'"
                          :componentType="'coverImage'"
                        />
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogclose()">
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!addcat"
                      @click="naturalistadd()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex
            xs12
            md6
            sm6
            lg4
            xl3
            align-self-center
            v-for="(item, i) in category"
            :key="i"
            fill-height
            pa-4
          >
            <v-card tile flat outlined>
              <v-layout wrap justify-center py-4>
                <v-flex xs12 align-self-start text-left pa-3 v-if="item">
                  <!-- <span> -->
                  <v-list-item-avatar v-if="item.image">
                    <v-img contain :src="mediaURL + item.image"></v-img>
                  </v-list-item-avatar>
                  <!-- </span> -->
                  <span style="font-family: poppinsmedium">{{
                    item.name
                  }}</span>
                </v-flex>
                <!-- </v-layout>
                <v-layout wrap justify-center py-4> -->
                <v-flex xs12 sm6 lg6 pa-2>
                  <v-btn
                    color="success"
                    style="font-family: poppinsmedium"
                    small
                    block
                    outlined
                    @click="editcat(item)"
                    >Edit</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 lg6 pa-2>
                  <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: poppinsmedium"
                        class="mr-2"
                        outlined
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Naturalist?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <!-- <v-flex xs12  pa-2>
                  <v-btn
                    color="blue"
                    style="font-family: poppinsmedium"
                    small
                    block
                    outlined
                    :to="
                      'ProductSubType?sub=' + item._id + '&name=' + item.name
                    "
                    >subTypes</v-btn
                  >
                </v-flex> -->
              </v-layout>
            </v-card>
          </v-flex>
          <v-dialog persistent v-model="editdialog" max-width="70%">
            <v-card tile>
              <v-card-title>
                <span class="headline">Edit Naturalist</span>
              </v-card-title>
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs12 pt-5 text-left>
                    <v-text-field
                      v-model="editingitem.name"
                      label="Naturalist Name"
                      :rules="[rules.required]"
                      outlined
                      dense
                      required
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 pt-5 text-left v-if="flagg">
                    <ImageComp
                      @stepper="winStepper"
                      :singleImage="editingitem.image"
                      :pageId="editingitem._id"
                      :height="'2118'"
                      :width="'8001'"
                      :heading="'Upload Icon Image'"
                      :componentType="'coverImage'"
                    />
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="getdData">
                  Close
                </v-btn>
                <v-btn
                  v-if="!g"
                  color="blue darken-1"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ImageComp from "./singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      headers: [
        { text: "Category Name", value: "name" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
      category: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
    };
  },
  components: {
    ImageComp,
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    winStepper(window_data) {
      if (window_data) {
        console.log("win", window_data);
      }
      if (window_data.type == "coverImage") {
        this.coverImageFile = window_data.selectedFiles;
      }
    },
    getdData() {
      this.editdialog = false;
      this.getData();
    },
    initialize() {
      this.admin = [];
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/naturalist/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/naturalist/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    naturalistadd() {
      var data = {};
      data["name"] = this.name;
      axios({
        url: "/naturalist/add",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.coverImageFile) {
              this.uploadCoverImages(response.data.id);
            }
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            if (!this.coverImageFile) {
              this.getData();
            }
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadCoverImages(id) {
      this.flagg = false;
      this.appLoading = true;
      this.formDataCover.append("id", id);
      this.formDataCover.append("image", this.coverImageFile);
      axios({
        method: "POST",
        url: "/naturalist/image/",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.formDataCover = new FormData();
            this.msg = response.data.msg;
          } else {
            this.msg = "Can't Upload Cover Image.. Please Try Again Later";
            this.showSnackBar = true;
            this.formDataCover = new FormData();
          }
          this.formDataCover = new FormData();
          this.getData();
          this.flagg = true;
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      axios({
        url: "/naturalist/edit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            if (this.coverImageFile) {
              this.uploadCoverImages(this.editingitem._id);
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
